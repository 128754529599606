// tslint:disable-next-line: no-submodule-imports
import 'core-js/stable';
// tslint:disable-next-line: no-submodule-imports
import 'regenerator-runtime/runtime';
import './app/embedding/dwt-app';

const polyfillIO = document.createElement('script');
polyfillIO.src =
  'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=IntersectionObserver%2CIntersectionObserverEntry%2Cfetch%2CIntl.DateTimeFormat%2CIntl.Locale';
document.head.appendChild(polyfillIO);
