import onMapIcon from '../../../../assets/ssangyong/icon/on_map.svg';
import phoneIcon from '../../../../assets/ssangyong/icon/phoneIcon.svg';
import phoneIconBlack from '../../../../assets/ssangyong/icon/phoneIconBlack.svg';
import ssgCommercialCarsSalesIcon from '../../../../assets/ssangyong/icon/services/commercialsales.svg';
import ssgCommercialCarsServiceIcon from '../../../../assets/ssangyong/icon/services/service.svg';
import ssgExclusiveAmperaIcon from '../../../../assets/ssangyong/icon/services/carosserie.svg';
import ssgPaintAndBodyRepairIcon from '../../../../assets/ssangyong/icon/services/paintandbodyrepair.svg';
import ssgPassengerCarsSalesIcon from '../../../../assets/ssangyong/icon/services/passengercarsales.svg';
import ssgPartsStoreIcon from '../../../../assets/ssangyong/icon/services/parts.svg';
import ssgUsedCarsIcon from '../../../../assets/ssangyong/icon/services/usedcars.svg';
import ssgDefaultServiceIcon from '../../../../assets/ssangyong/icon/services/default_service_icon.svg';
import chevronDownIcon from '../../../../assets/ssangyong/icon/chevron-down.svg';
import docExtension from '../../../../assets/ssangyong/icon/extension.svg';
import ssgPoiActive from '../../../../assets/ssangyong/icon/ssg-poi-active.svg';
import ssgPoiActiveWhiteFilled from '../../../../assets/ssangyong/icon/ssg-poi-active-white-filled.svg';
import ssgPoiInactive from '../../../../assets/ssangyong/icon/ssg-poi-inactive.svg';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import plusIcon from '../../../../assets/ssangyong/icon/plus.svg';
import minusIcon from '../../../../assets/ssangyong/icon/minus.svg';
import ssgPhoneIcon from '../../../../assets/ssangyong/icon/phone.svg';
import chevronRight from '../../../../assets/ssangyong/icon/chevron-right.svg';
import chevronLeft from '../../../../assets/ssangyong/icon/chevron-left.svg';
import closeIcon from '../../../../assets/ssangyong/icon/close_icon24x24.svg';
import backArrowIcon from '../../../../assets/ssangyong/icon/back_arrow.svg';
import ssgCalendar from '../../../../assets/ssangyong/icon/calendrier_50x50.svg';
import blueCheckMarkIcon from '../../../../assets/ssangyong/icon/blue-check-mark.svg';
import breadcrumbArrow from '../../../../assets/ssangyong/icon/breadcrumb-arrow.svg';
import buttonArrowRight from '../../../../assets/ssangyong/icon/button-arrow-right.svg';
import infoIcon from '../../../../assets/ssangyong/icon/info-icon.svg';
import popupCloseIcon from '../../../../assets/ssangyong/icon/popup_close_icon24x24.svg';
import timeIcon from '../../../../assets/ssangyong/icon/time.svg';
import wltpIcon from '../../../../assets/ssangyong/icon/wltp.svg';

import formDateInput from '../../../../assets/ssangyong/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/ssangyong/icon/form-time-input.svg';

import deleteIcon from '../../../../assets/ssangyong/icon/delete.svg';

export const ssgAssets = Object.freeze({
  ssgPoiActive,
  ssgPoiActiveWhiteFilled,
  ssgPoiInactive,
  onMapIcon,
  phoneIcon,
  phoneIconBlack,
  ssgCommercialCarsSalesIcon,
  ssgCommercialCarsServiceIcon,
  ssgExclusiveAmperaIcon,
  ssgPaintAndBodyRepairIcon,
  ssgPassengerCarsSalesIcon,
  ssgPassengerCarsServiceIcon: ssgCommercialCarsServiceIcon,
  ssgPartsStoreIcon,
  ssgDefaultServiceIcon,
  ssgUsedCarsIcon,
  chevronDownIcon,
  docExtension,
  noImagePlaceholder,
  plusIcon,
  minusIcon,
  ssgPhoneIcon,
  chevronRight,
  chevronLeft,
  closeIcon,
  backArrowIcon,
  ssgCalendar,
  blueCheckMarkIcon,
  breadcrumbArrow,
  buttonArrowRight,
  infoIcon,
  popupCloseIcon,
  timeIcon,
  wltpIcon,
  formDateInput,
  formTimeInput,
  deleteIcon,
});
