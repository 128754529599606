import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import backArrow from '../../../../assets/ds/icon/backArrow.svg';
import checkboxTick from '../../../../assets/ds/icon/checkboxTick.svg';
import checkMark from '../../../../assets/ds/icon/checkMark.svg';
import closeIcon from '../../../../assets/ds/icon/closeIcon.svg';
import downArrow from '../../../../assets/ds/icon/downArrow.svg';
import poiPartnerBig from '../../../../assets/ds/icon/poiPartnerBig.png';
import poiPartner from '../../../../assets/ds/icon/poiPartner.png';
import poiSalonBig from '../../../../assets/ds/icon/poiSalonBig.png';
import poiSalon from '../../../../assets/ds/icon/poiSalon.png';
import poiServiceBig from '../../../../assets/ds/icon/poiServiceBig.png';
import poiService from '../../../../assets/ds/icon/poiService.png';
import poiStoreBig from '../../../../assets/ds/icon/poiStoreBig.svg';
import poiStore from '../../../../assets/ds/icon/poiStore.svg';
import deleteIcon from '../../../../assets/ds/icon/delete.svg';
// TODO - DWT-36684: import the different icons for different energyClasses
import energyC from '../../../../assets/ds/icon/energie-c.png';
import filterOpener from '../../../../assets/ds/icon/filterOpener.svg';
import nextArrow from '../../../../assets/ds/icon/nextArrow.svg';
import nextArrowLight from '../../../../assets/ds/icon/nextArrowLight.svg';
import nextArrowSmall from '../../../../assets/ds/icon/nextArrowSmall.svg';
import emailIcon from '../../../../assets/ds/icon/emailIcon.svg';
import phoneIcon from '../../../../assets/ds/icon/phoneIcon.svg';
import infoIcon from '../../../../assets/ds/icon/infoIcon.svg';
import prevArrow from '../../../../assets/ds/icon/prevArrow.svg';
import prevArrowLight from '../../../../assets/ds/icon/prevArrowLight.svg';
import prevArrowSmall from '../../../../assets/ds/icon/prevArrowSmall.svg';
import redNextArrow from '../../../../assets/ds/icon/redNextArrow.svg';
import redPrevArrow from '../../../../assets/ds/icon/redPrevArrow.svg';
import onMapIcon from '../../../../assets/ds/icon/on_map.svg';
import openHoursIcon from '../../../../assets/ds/icon/openHoursIcon.svg';
import dsPartsStoreIcon from '../../../../assets/ds/icon/services/parts.svg';
import dsPassengerCarsSalesIcon from '../../../../assets/ds/icon/services/passengercarsales.svg';
import dsPassengerCarsServiceIcon from '../../../../assets/ds/icon/services/service.svg';
import dsUsedCarsIcon from '../../../../assets/ds/icon/services/usedcars.svg';
import dsDefaultServiceIcon from '../../../../assets/ds/icon/services/default_service_icon.svg';
import dsDsServiceValetIcon from '../../../../assets/ds/icon/services/2062_DSvalet.svg';
import dsDsVirtualVisionIcon from '../../../../assets/ds/icon/services/2070_VirtualVisionDS.svg';
import dsRepriseAutobizIcon from '../../../../assets/ds/icon/services/2065_PurchaseOffer.svg';
import dsMemberPlaceholder from '../../../../assets/ds/image/member_placeholder.png';
import calendar from '../../../../assets/ds/icon/calendar.svg';
import formClock from '../../../../assets/ds/icon/formClock.png';
import info from '../../../../assets/ds/icon/showroom_info.svg';
import anchorDown from '../../../../assets/ds/icon/anchor_down_banner.svg';

import formDateInput from '../../../../assets/ds/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/ds/icon/form-time-input.svg';
import docExtension from '../../../../assets/ds/icon/extension.svg';

export const dsAssets = Object.freeze({
  backArrow,
  checkboxTick,
  checkMark,
  closeIcon,
  downArrow,
  energyC,
  filterOpener,
  nextArrow,
  nextArrowLight,
  nextArrowSmall,
  noImagePlaceholder,
  prevArrow,
  prevArrowLight,
  prevArrowSmall,
  emailIcon,
  phoneIcon,
  infoIcon,
  redNextArrow,
  redPrevArrow,
  poiPartnerBig,
  poiPartner,
  poiSalonBig,
  poiSalon,
  poiServiceBig,
  poiService,
  poiStoreBig,
  poiStore,
  onMapIcon,
  openHoursIcon,
  dsPartsStoreIcon,
  dsPassengerCarsSalesIcon,
  dsPassengerCarsServiceIcon,
  dsUsedCarsIcon,
  dsDefaultServiceIcon,
  dsDsServiceValetIcon,
  dsDsVirtualVisionIcon,
  dsRepriseAutobizIcon,
  dsMemberPlaceholder,
  calendar,
  formClock,
  info,
  anchorDown,
  formDateInput,
  formTimeInput,
  deleteIcon,
  docExtension,
});
